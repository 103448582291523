import CodeEditor from '@uiw/react-textarea-code-editor';
import React, { useRef, useState } from 'react';
import { useDebounce } from 'react-use';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Greenish, Redish } from 'libs/css';
import { UncontrolledTooltip } from "reactstrap";

function validateInput(raw) {
    try {
        const json = JSON.parse(raw);
        return {json, raw, valid: true};
    } catch (error) {
        return {valid: false, raw, error: error.toString()};
    }
}

function JSONInput({
                       value,
                       onChange: providedOnChange,
                       disabled = false, validate = true,
                       height = '100%', width = '100%', className = '', id = ''
                   }) {
    const ref = useRef(null);
    const validatedValue = validateInput(JSON.stringify(value));
    const [valid, setValid] = useState(validatedValue.valid);
    const [error, setError] = useState(validatedValue.error);
    const [newValue, setNewValue] = useState(null);

    useDebounce(() => {
        if (newValue === null) return;
        const validationOutput = validateInput(newValue);
        setValid(validationOutput.valid);
        setError(validationOutput.error);
        if (validationOutput.valid && providedOnChange && JSON.stringify(value) !== JSON.stringify(validationOutput.json)) {
            providedOnChange(validationOutput);
        }
    }, 250, [newValue, value]);

    return <div className={`position-relative ${className}`}>
        <CodeEditor
            id={id}
            value={JSON.stringify(value, null, 4)}
            language="json"
            onChange={(evt) => setNewValue(evt.target.value)}
            padding={15}
            style={{fontFamily: 'monospace', height, width, overflow: 'auto'}}
            data-color-mode="dark"
            disabled={disabled}
        />
        {validate && <span className="position-absolute" style={{top: 2, right: 2}} title={error ?? 'JSON is valid'}>
            <FontAwesomeIcon icon={valid ? faCheck : faTimes} fixedWidth size="xl" color={valid ? Greenish : Redish} ref={ref}/>
            <UncontrolledTooltip target={ref}>
                {error ?? 'JSON is valid'}
            </UncontrolledTooltip>
        </span>}
    </div>;
}

export default JSONInput;
