const ERROR_MESSAGE = 'Error fetching token, please refresh the page and try again';
let authPromise;
const getAuthPromise = () => {
  return window.oktaAuth.token.getWithoutPrompt().then(res => {
    window.oktaAuth.tokenManager.setTokens(res.tokens);
    return res.tokens.accessToken.accessToken;
  }).catch(error => {
    console.error(`[getAuth]`, error);
    throw Error(ERROR_MESSAGE);
  }).finally(() => {
    authPromise = null;
  });
};
const isTokenAlive = token => token.accessToken && !window.oktaAuth.tokenManager.hasExpired(token.accessToken);
export async function getToken() {
  if (!window.oktaAuth) throw Error(ERROR_MESSAGE);
  const tokens = await window.oktaAuth.tokenManager.getTokens();
  if (isTokenAlive(tokens)) return tokens.accessToken.accessToken;
  if (authPromise) return authPromise;
  return getAuthPromise();
}